import React from "react";
import { twMerge } from "tailwind-merge";

import Button from "../../Button";

type InputProps = {
  children?: React.ReactNode;
  className?: string;
  clearable?: boolean;
  onClear?: () => void;
  errors?: string[];
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

const Input = ({
  children,
  className = "",
  clearable,
  onClear,
  value,
  errors,
  ...rest
}: InputProps) => {
  const isError = errors && errors.length > 0;
  return (
    <div className="relative">
      <input
        className={twMerge(
          "h-10 bg-chinese-black border rounded-md border-[#1F293D] px-3",
          isError ? "border-foundation-red" : "",
          className
        )}
        {...rest}
        value={value}
      >
        {children}
      </input>

      {clearable && (
        <Button
          disabled={value === undefined || value === null || value === ""}
          className="absolute right-3 top-2 text-foundation-blue opacity-100 disabled:opacity-40 bg-opacity-20 bg-foundation-blue"
          onClick={onClear}
          small
        >
          Clear
        </Button>
      )}

      {isError && (
        <ul className="mt-1 -mb-3">
          {errors.map((error) => (
            <li className="text-foundation-red text-xs" key={error}>
              {error}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Input;
