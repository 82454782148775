import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import SuccessRequestToast from "./SuccessRequestToast";
import FaucetFormHeader from "./FaucetFormHeader";
import FaucetFormBody from "./FaucetFormBody";
import FaucetFormFooter from "./FaucetFormFooter";

const HorizenTestTokenFaucet = () => {
  const txid = useSelector((state: RootState) => state.faucetForm.txid);

  return (
    <div className="space-y-4">
      <div className="bg-dark-blue-magenta max-w-[600px] mx-auto p-4 md:p-8 rounded-[5px]">
        <div className="space-y-6">
          <FaucetFormHeader />
          <FaucetFormBody />
          <FaucetFormFooter />
        </div>
      </div>
      {txid && <SuccessRequestToast txid={txid} />}
    </div>
  );
};

export default HorizenTestTokenFaucet;
