import React from "react";
import HorizenLogoSmall from "../../../assets/logos/HorizenLogoSmall";
import Typography from "../../../components/Typography";
import { useFaucet } from "../hooks/useFaucet";
import { renderZenAmount } from "./FaucetFormBody";

const FaucetFormHeader = () => {
  const { zenAmount, isZenAmountLoading } = useFaucet();

  return (
    <>
      <div className="flex items-center">
        <HorizenLogoSmall className="mr-2" />
        <Typography.H1>
          {process.env.REACT_APP_IS_YUMA_FAUCET === "true"
            ? "Yuma Faucet"
            : "Horizen Test Token Faucet"}
        </Typography.H1>
      </div>

      <div className="bg-chinese-black px-[14px] py-[10px] rounded-[5px]">
        <Typography.TextSM className="text-jungle-mist">
          <>
            {process.env.REACT_APP_IS_YUMA_FAUCET === "true"
              ? "This faucet transfers TZEN tokens to Yuma testnet."
              : "This faucet transfers TZEN tokens on Horizen testnet chains."}{" "}
            The tokens are for testnet only and cannot be redeemed for real
            funds. Withdrawals are limited to one request per 24 hours and the
            amount received is {renderZenAmount(isZenAmountLoading, zenAmount)}{" "}
            TZEN.
          </>
        </Typography.TextSM>
      </div>
    </>
  );
};

export default FaucetFormHeader;
