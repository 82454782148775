import axios from "axios";
import { URL_SIDECHAINS_ID_API } from "../../../routes/externals";

const getSidechainsInfo = () => {
  if (process.env.REACT_APP_IS_YUMA_FAUCET === "true") {
    return Promise.resolve([
      {
        value:
          "3ead90fecb990f3ca2e2be1218f3b90037a5a2ff9d82438f06dfca4083ea4512",
        label: "Yuma Testnet",
      },
    ]);
  } else {
    return axios.get(`${URL_SIDECHAINS_ID_API}/sidechains`).then((response) => {
      const sidechains = response.data.data.sidechains;
      sidechains.forEach((sidechain: any) => {
        sidechain.value = sidechain.id;
        sidechain.label = sidechain.name;
        delete sidechain.id;
        delete sidechain.name;
      });
      return sidechains;
    });
  }
};

export default getSidechainsInfo;
