import React, { useCallback } from "react";
import { URL_HORIZEN_MAINCHAIN_TESTNET_EXPLORER } from "../../../routes/externals";
import Button from "../../../components/Button";
import Typography from "../../../components/Typography";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { MATOMO_CONSTANTS } from "../../../constants";

const MainchainSecondaryActions = () => {
  const { trackEvent } = useMatomo();

  const onViewBlockExplorer = useCallback(() => {
    trackEvent({
      category: MATOMO_CONSTANTS.EVENT_TRIGGER_CATEGORY,
      action: MATOMO_CONSTANTS.EVENT_TRIGGER_ACTIONS.VIEW_BLOCK_EXPLORER,
    });
  }, [trackEvent]);

  return (
    <div className="space-y-6">
      <Typography.TextSM className="text-jungle-mist">
        Visit the <span className="font-bold">Horizen Mainchain Testnet</span>{" "}
        block explorer.
      </Typography.TextSM>
      <Button
        href={URL_HORIZEN_MAINCHAIN_TESTNET_EXPLORER}
        isExternal
        className="block w-full text-center"
        variant="outline"
        onClick={onViewBlockExplorer}
      >
        View Block Explorer
      </Button>
    </div>
  );
};

export default MainchainSecondaryActions;
