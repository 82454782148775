import React from "react";
import { Helmet as ReactHelmet } from "react-helmet";

const getCurrentUrlDomain = () => {
  return window.location.protocol + "//" + window.location.hostname;
};

const Helmet = () => {
  return (
    <ReactHelmet>
      <meta charSet="utf-8" />
      <title>
        {process.env.REACT_APP_IS_YUMA_FAUCET === "true"
          ? "Yuma Faucet"
          : "Horizen Test Token Faucet"}
      </title>
      <link rel="icon" href={`${process.env.PUBLIC_URL}/favicon.png`} />
      <link
        rel="preload"
        as="style"
        href="https://use.typekit.net/elr1akh.css"
      />
      <link rel="stylesheet" href="https://use.typekit.net/elr1akh.css" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
      <meta
        name="description"
        content="Get testnet tokens sent to your wallet to use on Horizen testnet chains including Dune, TokenMint, and the Horizen Mainchain"
      />
      <meta property="og:title" content="Horizen Test Token Faucet" />
      <meta property="og:site_name" content="Horizen Test Token Faucet" />
      <meta property="og:url" content="https://faucet.horizen.io/" />
      <meta
        property="og:description"
        content="Get testnet tokens sent to your wallet to use on Horizen testnet chains including Dune, TokenMint, and the Horizen Mainchain"
      />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta property="twitter:title" content="Horizen Test Token Faucet" />
      <meta
        name="twitter:image"
        content={`${getCurrentUrlDomain()}/assets/meta.jpg`}
      />
      <meta
        property="twitter:description"
        content="Get testnet tokens sent to your wallet to use on Horizen testnet chains including Dune, TokenMint, and the Horizen Mainchain"
      />
      <meta
        property="og:image"
        content={`${getCurrentUrlDomain()}/assets/meta.jpg`}
      />
      <link rel="canonical" href="https://faucet.horizen.io/" />
      <link rel="manifest" href={`${process.env.PUBLIC_URL}/manifest.json`} />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
    </ReactHelmet>
  );
};

export default Helmet;
