import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import Button from "../../../components/Button";
import { Label } from "../../../components/Forms";
import Input from "../../../components/Forms/Input";
import Select from "../../../components/Select";
import { updateSelectedTesnet, updateTxid } from "../FaucetFormSlice";
import { useFaucet } from "../hooks/useFaucet";
import GoogleRecaptcha from "../../../components/GoogleRecaptcha/GoogleRecaptcha";
import ReCAPTCHA from "react-google-recaptcha-enterprise";
import { LoadingCircle } from "../../../assets/icons";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { MATOMO_CONSTANTS } from "../../../constants";

import useSidechainsApi from "../hooks/useSidechainsApi";

export const mainchainId = "mainchain-testnet";

export const renderZenAmount = (
  isZenAmountLoading: boolean,
  zenAmount: string | null
) => {
  if (isZenAmountLoading) {
    return <LoadingCircle />;
  }
  if (zenAmount) {
    return <>{zenAmount}</>;
  }
};

const FaucetFormBody = () => {
  const { sidechainsInfo, isSidechainsInfoError, isSidechainsInfoSuccess } =
    useSidechainsApi();

  const dispatch = useDispatch();

  const testnetList = useMemo(
    () => [{ value: mainchainId, label: "Horizen Mainchain Testnet" }],
    []
  );

  const [testnetListIsReady, setTestnetListIsReady] = useState(false);

  useEffect(() => {
    if (isSidechainsInfoSuccess) {
      testnetList.splice(0, testnetList.length - 1);
      testnetList.unshift(...(sidechainsInfo || []));
      setTestnetListIsReady(true);
    } else if (isSidechainsInfoError) {
      setTestnetListIsReady(true);
    }
    dispatch(updateSelectedTesnet(testnetList[0].value));
  }, [
    isSidechainsInfoSuccess,
    isSidechainsInfoError,
    sidechainsInfo,
    testnetList,
    dispatch,
  ]);

  const [walletAddress, setWalletAddress] = useState("");

  const selectedTestnet = useSelector(
    (state: RootState) => state.faucetForm.selectedTestnet
  );

  const [errors, setErrors] = useState([] as string[]);

  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const {
    isFaucetRequestLoading,
    isFaucetRequestError,
    isFaucetRequestSuccess,
    requestFundsToFaucet,
    txid,
    faucetRequestErrors,
    resetRequest,
    zenAmount,
    isZenAmountLoading,
  } = useFaucet();

  useEffect(() => {
    if (isFaucetRequestError) {
      const processedErrors = [];
      if (faucetRequestErrors.response.data.errors) {
        const numOfErrors = faucetRequestErrors.response?.data.errors.length;
        for (let i = 0; i < numOfErrors; i += 1) {
          const e = faucetRequestErrors.response.data.errors[i];
          if (e.msg.includes("address has already been used")) {
            processedErrors.push(
              "Withdrawal request limited to once every 24 hours"
            );
            break;
          }
          if (
            e.msg.includes("address must be") ||
            e.msg.includes("Invalid address")
          ) {
            processedErrors.push("Invalid address");
          }
        }
      } else if (faucetRequestErrors.response.data.includes("out of funds")) {
        processedErrors.push("Faucet is out of funds");
      } else {
        processedErrors.push("Something was wrong. Try again later.");
      }
      setErrors(processedErrors);
    }
  }, [isFaucetRequestError, faucetRequestErrors]);

  useEffect(() => {
    if (isFaucetRequestSuccess) {
      dispatch(updateTxid(txid));
    }
  }, [isFaucetRequestSuccess, txid, dispatch]);

  const { trackEvent } = useMatomo();

  const onRequestTokens = useCallback(() => {
    const selectedNet = testnetList.find(
      (net) => net.value === selectedTestnet
    );

    trackEvent({
      category: MATOMO_CONSTANTS.EVENT_TRIGGER_CATEGORY,
      action: MATOMO_CONSTANTS.EVENT_TRIGGER_ACTIONS.REQUEST_TOKENS,
      name: selectedNet?.label + " - " + selectedNet?.value,
    });
  }, [selectedTestnet, testnetList, trackEvent]);

  const onClearInput = useCallback(() => {
    setWalletAddress("");
    dispatch(updateTxid(null));
    setErrors([]);
    resetRequest();
    trackEvent({
      category: MATOMO_CONSTANTS.EVENT_TRIGGER_CATEGORY,
      action: MATOMO_CONSTANTS.EVENT_TRIGGER_ACTIONS.CLEAR_ADDRESS,
    });
  }, [dispatch, resetRequest, trackEvent]);

  const onError = useCallback(() => {
    trackEvent({
      category: MATOMO_CONSTANTS.EVENT_TRIGGER_CATEGORY,
      action: MATOMO_CONSTANTS.EVENT_TRIGGER_ACTIONS.REQUEST_TOKENS_ERROR,
      name: errors.join(", "),
    });
  }, [errors, trackEvent]);

  useEffect(() => {
    if (errors.length > 0) {
      onError();
    }
  }, [errors, onError]);

  return (
    <form
      className="space-y-5"
      onSubmit={async (e) => {
        e.preventDefault();
        if (isFaucetRequestSuccess === false && recaptchaRef.current) {
          const token = (await recaptchaRef.current.executeAsync()) || "";
          requestFundsToFaucet({
            address: walletAddress,
            token,
            network: selectedTestnet,
          });
          setErrors([]);
        }
      }}
    >
      <div>
        <Label className="pb-[6px] block">Select Testnet</Label>
        <Select
          options={!testnetListIsReady ? [] : testnetList}
          isLoading={!testnetListIsReady}
          value={
            !testnetListIsReady
              ? {}
              : testnetList.find(
                  (testnet) => testnet.value === selectedTestnet
                ) || testnetList[0]
          }
          isDisabled={
            isFaucetRequestLoading ||
            isFaucetRequestSuccess ||
            !testnetListIsReady
          }
          onChange={(e: any) => dispatch(updateSelectedTesnet(e.value))}
        />
        {isSidechainsInfoError && (
          <div className="text-red-500 text-xs mt-1">
            There was an error loading the sidechains. Please try again later.
          </div>
        )}
      </div>
      <div>
        <Label className="pb-[6px] block">Wallet Address</Label>
        <Input
          type="text"
          name="address"
          id="address"
          // placeholder="Connect wallet or enter address manually..."
          placeholder="Enter address manually..."
          className="w-full pr-16 text-ellipsis"
          value={walletAddress}
          onChange={(e) => setWalletAddress(e.target.value)}
          onClear={onClearInput}
          errors={errors}
          clearable
        />
      </div>

      <Button
        variant="gradient"
        type="submit"
        className={`w-full mt-4 ${isFaucetRequestSuccess && "cursor-default"}`}
        disabled={walletAddress === ""}
        loading={isFaucetRequestLoading}
        onClick={onRequestTokens}
      >
        {isFaucetRequestLoading ? (
          "Requesting..."
        ) : isFaucetRequestSuccess ? (
          "Submitted"
        ) : (
          <>Request {renderZenAmount(isZenAmountLoading, zenAmount)} TZEN</>
        )}
      </Button>

      <GoogleRecaptcha recaptchaRef={recaptchaRef} />
    </form>
  );
};

export default FaucetFormBody;
