import React, { useRef } from "react";
import { CheckCircle, Cross, ExternalLink } from "../../../assets/icons";
import { URL_HORIZEN_MAINCHAIN_TESTNET_EXPLORER } from "../../../routes/externals";

import Link from "../../../components/Link";
import { useDispatch } from "react-redux";
import { updateTxid } from "../FaucetFormSlice";

type SuccessRequestToastProps = {
  txid: string;
};

const SuccessRequestToast = ({ txid }: SuccessRequestToastProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const truncate = (str: string) => {
    return str.slice(0, 8) + "..." + str.slice(-8);
  };

  return (
    <div>
      <div
        ref={ref}
        className="flex space-x-4 bg-dark-blue-magenta max-w-[600px] mx-auto p-4 rounded-[5px] border-cyan-blue border relative"
      >
        <Cross
          onClick={() => {
            dispatch(updateTxid(null));
            ref.current?.remove();
          }}
          className="cursor-pointer absolute top-4 right-4"
        />
        <CheckCircle />
        <div className="text-sm leading-5 space-y-1">
          <span className="font-semibold block">Request Submitted</span>
          <span className="block text-jungle-mist">
            Transaction ID: {truncate(txid)}
          </span>
          <Link
            href={`${URL_HORIZEN_MAINCHAIN_TESTNET_EXPLORER}/tx/${txid}`}
            external
            className="font-semibold flex items-center"
          >
            View in Block Explorer
            <ExternalLink />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SuccessRequestToast;
