import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface FaucetFormState {
  selectedTestnet: string;
  txid: string | null;
}

const initialState: FaucetFormState = {
  selectedTestnet: "",
  txid: null,
};

export const FaucetFormSlice = createSlice({
  name: "selectedTestnet",
  initialState,
  reducers: {
    updateSelectedTesnet: (state, action: PayloadAction<string>) => {
      state.selectedTestnet = action.payload;
    },
    updateTxid: (state, action: PayloadAction<string | null>) => {
      state.txid = action.payload;
    },
  },
});

export const { updateSelectedTesnet, updateTxid } = FaucetFormSlice.actions;

export default FaucetFormSlice.reducer;
